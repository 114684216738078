<template>
	<ValidationObserver ref="form">
		<b-card style="font-size: 14px">
			<b-row>
				<b-col cols="12" md="4">
					<b-avatar
						class="clickable text-dark w-100"
						style="border: 2px dashed var(--dark)"
						ref="previewEl"
						icon="image"
						square
						rounded="lg"
						variant="light"
						:src="createSpecimen.photo"
						:size="heightImage"
						@click="$refs.fileInput.click()"
					/>
					<div class="d-flex justify-content-end">
						<span>
							<feather-icon
								size="22"
								icon="XOctagonIcon"
								class="cursor-pointer text-danger mr-25"
								@click="deletePhoto()"
							/>
							<feather-icon
								size="22"
								icon="UploadIcon"
								class="cursor-pointer text-primary"
								@click="$refs.fileInput.click()"
							/>
						</span>
					</div>
					<input
						ref="fileInput"
						type="file"
						accept=".jpeg,.jpg,.png,.heif,.heic"
						@input="pickFile"
						class="d-none"
					/>
				</b-col>
				<b-col cols="12" md="8">
					<b-row>
						<b-col cols="12" md="4">
							<h3 class="mb-2 font-weight-bolder">Detalles Generales</h3>
							<b-form-group label-class="label-size" label="ORIGEN">
								<b-form-select
									v-model="createSpecimen.origin_id"
									value-field="id"
									text-field="name"
									:options="G_ORIGINS_SPECIMENS_FILTER"
								/>
							</b-form-group>
							<!--  -->
							<b-form-group
								label-class="label-size"
								:label="`Linea del ejemplar (` + createSpecimen.lines.length + `)`"
								v-if="createSpecimen.origin_id != 1"
							>
								<b-button
									class="mr-1"
									size="sm"
									variant="primary"
									@click="
										;(breederLineModal.show = !breederLineModal.show),
											(breederLineModal.info = { lines: createSpecimen.lines })
									"
								>
									<FeatherIcon class="mx-1 cursor-pointer" icon="Edit3Icon" size="18" />
								</b-button>
								<!--  -->
								<ValidationProvider rules="" v-slot="{ errors }">
									<input class="hidden" v-model="createSpecimen.lines" />
									<br />
									<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
								</ValidationProvider>
							</b-form-group>
							<!--  -->
							<b-form-group
								label-class="label-size"
								:label="`Raza del ejemplar (` + createSpecimen.races.length + `)`"
								v-if="createSpecimen.origin_id != 1"
							>
								<b-button
									class="mr-1"
									size="sm"
									variant="primary"
									@click="
										;(raceModal.show = !raceModal.show),
											(raceModal.info = { races: createSpecimen.races, creating: true })
									"
								>
									<FeatherIcon class="mx-1 cursor-pointer" icon="Edit3Icon" size="18" />
								</b-button>
								<!--  -->
								<ValidationProvider rules="" v-slot="{ errors }">
									<input class="hidden" v-model="createSpecimen.races" />
									<br />
									<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
								</ValidationProvider>
							</b-form-group>
							<!--  -->
							<template v-if="[1, 2].includes(createSpecimen.origin_id)">
								<b-form-group label-class="label-size">
									<template #label>
										<span>PADRILLO</span>
										<feather-icon
											class="clickable float-right"
											icon="PlusIcon"
											@click="
												;(modalFastParent.padrillo = true),
													(modalFastParent.show = !modalFastParent.show)
											"
										/>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<input class="hidden" v-model="createSpecimen.padrillo_id" />
										<SelectSpecimens
											:class="{ 'border-danger rounded': errors[0] }"
											:forceModel="helperPadrillo"
											@selected="($event) => (createSpecimen.padrillo_id = $event.specimen_id)"
											categories="1"
											:key="createSpecimen.origin_id"
										/>
										<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
									</ValidationProvider>
								</b-form-group>
								<!--  -->
								<b-form-group label-class="label-size">
									<template #label>
										<span>MADRILLA</span>
										<feather-icon
											class="clickable float-right"
											icon="PlusIcon"
											@click="
												;(modalFastParent.padrillo = false),
													(modalFastParent.show = !modalFastParent.show)
											"
										/>
									</template>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<input class="hidden" v-model="createSpecimen.madrilla_id" />
										<SelectSpecimens
											:class="{ 'border-danger rounded': errors[0] }"
											:forceModel="helperMadrilla"
											@selected="($event) => (createSpecimen.madrilla_id = $event.specimen_id)"
											categories="2"
											:key="createSpecimen.origin_id"
										/>
										<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
									</ValidationProvider>
								</b-form-group>
							</template>
							<b-form-group label-class="label-size" v-if="createSpecimen.origin_id !== 1">
								<template #label>
									<div class="d-flex justify-content-between align-items-center">
										<span>CRIADOR</span>
										<span class="clickable" @click="addCriadorModal = true">
											<feather-icon icon="PlusIcon" />
										</span>
									</div>
								</template>
								<ValidationProvider
									:rules="createSpecimen.origin_id == 2 ? 'required' : ''"
									v-slot="{ errors }"
								>
									<v-select
										id="breeder"
										v-model="createSpecimen.breeder_id"
										:clearable="false"
										label="name"
										:options="S_BREEDERS"
										:reduce="(option) => option.id"
										:class="{ 'border-danger rounded': errors[0] }"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
							</b-form-group>
							<!--  -->
							<b-form-group label-class="label-size" label="PRECIO" v-if="createSpecimen.origin_id === 2">
								<b-input-group size="md" id="precio">
									<template #prepend>
										<b-dropdown
											:text="createSpecimen.currency == 1 ? '$ ' : 'S/'"
											:variant="
												layout.skin == 'dark' ? 'outline-gray-1000' : 'outline-white-1000'
											"
										>
											<b-dropdown-item @click="setCurrencySpecimen(1)">$</b-dropdown-item>
											<b-dropdown-item @click="setCurrencySpecimen(2)">S/</b-dropdown-item>
										</b-dropdown>
									</template>
									<b-form-input
										id="price"
										v-model="createSpecimen.price"
										@keyup="justNumbersPrice()"
										@blur="fixedPrice()"
									/>
								</b-input-group>
							</b-form-group>
							<!--  -->
							<b-form-group label-class="label-size" label="CATEGORIA">
								<b-form-select
									v-model="createSpecimen.category_id"
									value-field="id"
									text-field="description"
									:options="categoryOpts"
								/>
							</b-form-group>
							<!--  -->
							<b-form-group label-class="label-size" label="GENERO" label-for="genero">
								<b-form-select
									class="text-dark"
									v-model="createSpecimen.gender_id"
									disabled
									value-field="id"
									text-field="description"
									:options="S_GENDERS_SPECIMENS"
								/>
							</b-form-group>
							<!--  -->
						</b-col>
						<b-col cols="12" md="4">
							<h3 class="mb-2 font-weight-bolder">Detalles Especificos</h3>
							<b-form-group label-class="label-size" label="PLACA">
								<ValidationProvider :rules="`${!isBorrowed ? 'required' : ''}`" v-slot="{ errors }">
									<b-form-input
										v-model="createSpecimen.plate"
										id="placa"
										:state="errors[0] || duplicatePlate ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
									<span v-if="duplicatePlate" style="color: red">Placa Duplicada</span>
								</ValidationProvider>
							</b-form-group>
							<!--  -->
							<div class="container-alias-deads">
								<p class="font-weight-bold label-size">
									Alias recomendados
									<span>(Click para seleccionar)</span>
								</p>
								<div class="grid-span">
									<v-select
										v-model="selectAliasDeadSpecimen"
										:options="aliasDeads"
										:clearable="false"
										transition=""
										placeholder="Buscar alias"
										:reduce="(option) => option"
										@input="setSelectAliasDeadSpecimen"
										class="w-100"
									/>
								</div>
							</div>
							<!--  -->
							<b-form-group
								label-class="label-size"
								label="PESO KG"
								v-if="![9, 10].includes(createSpecimen.new_status_id)"
							>
								<b-form-input
									id="weight"
									type="number"
									v-model="createSpecimen.weight"
									placeholder="0"
								/>
							</b-form-group>
							<!--  -->
							<b-form-group label-class="label-size" label="COLOR">
								<v-select
									id="color"
									v-model="createSpecimen.color_id"
									:clearable="false"
									label="color"
									:options="S_COLORS_SPECIMENS"
									:reduce="(option) => option.id"
									placeholder="Seleccionar color"
								/>
							</b-form-group>
							<!--  -->
							<SpecimenStatusChangedBody
								:specimen="createSpecimen"
								:arrayDeads="arrayDeads"
								:arrayReasons="arrayReasons"
								:arrayStatus="arrayStatustotal"
								:arrayDiseases="arrayDiseases"
								:arrayUsers="arrayUsers"
								:arrayClients="arrayClients"
								:isEditingAdmin="false"
								:isEditingStatusSpecimen="false"
								isCreatingNewSpecimen
							/>
						</b-col>
						<b-col cols="12" md="4">
							<h3 class="mb-2 text-white">|</h3>
							<b-form-group label-class="label-size" label="PLACA ADICIONAL" label-for="placa_adicional">
								<ValidationProvider :rules="`${isBorrowed ? 'required' : ''}`" v-slot="{ errors }">
									<b-input-group
										:prepend="isBorrowed ? (createSpecimen.origin_id == 5 ? 'P-' : 'R-') : ''"
									>
										<b-form-input
											id="placa_adicional"
											:state="isBorrowed ? (errors[0] ? false : null) : null"
											v-model="createSpecimen.plate_additional"
										/>
									</b-input-group>

									<span style="color: red">{{ errors[0] }}</span>
								</ValidationProvider>
							</b-form-group>
							<!--  -->
							<b-form-group label-class="label-size" label="ALIAS" label-for="alias">
								<template #label>
									<div class="d-flex align-item-center justify-content-between">
										<p class="mb-0">ALIAS</p>
										<FeatherIcon
											@click="clickDefinitionAlias"
											size="16"
											icon="InfoIcon"
											class="text-primary cursor-pointer"
											v-b-tooltip.hover
											title="Agregar definicion para identificar el ejemplar"
										/>
										<b-modal
											v-model="modalViewDefinitionAlias.show"
											:title="modalViewDefinitionAlias.title"
											@cancel="createSpecimen.definition = null"
										>
											<b-form-textarea
												v-model="createSpecimen.definition"
												placeholder="Agregar definicion"
												rows="3"
												max-rows="6"
											></b-form-textarea>
										</b-modal>
									</div>
								</template>
								<b-form-input
									id="alias"
									v-model="createSpecimen.alias"
									@input="() => (createSpecimen.alias = createSpecimen.alias.toUpperCase())"
								/>
							</b-form-group>
							<!--  -->

							<b-form-group
								label-class="label-size"
								label="PESO LB"
								v-if="![9, 10].includes(createSpecimen.new_status_id)"
							>
								<b-form-input
									id="pound"
									v-model="createSpecimen.pounds_weight"
									readonly
									placeholder="0"
								/>
							</b-form-group>
							<!--  -->
							<b-form-group label-class="label-size" label="NACIMIENTO">
								<flat-pickr
									id="dob"
									v-model="createSpecimen.dob"
									placeholder="Elige una Fecha"
									class="form-control bg-transparent"
									:config="config"
								/>
							</b-form-group>
							<!--  -->

							<b-form-group label-class="label-size" label="OBSERVACION">
								<b-form-textarea
									id="observation"
									v-model="createSpecimen.observation"
									placeholder="Ingrese una observación"
									rows="3"
									@input="
										() => (createSpecimen.observation = createSpecimen.observation.toUpperCase())
									"
								/>
							</b-form-group>
							<!--  -->
							<b-form-group label-class="label-size" label="Medidas del ejemplar">
								<b-button
									class="mr-1"
									size="sm"
									variant="primary"
									@click="
										;(measureModal.show = !measureModal.show),
											(measureModal.info = { races: createSpecimen.measurements, creating: true })
									"
								>
									<FeatherIcon class="mx-1 cursor-pointer" icon="Edit3Icon" size="18" />
								</b-button>
								<span v-if="createSpecimen.measurements.length != 0">
									{{ createSpecimen.measurements.length }} Medidas asignadas
								</span>
							</b-form-group>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-card>
		<div class="d-flex flex-column flex-md-row justify-content-center gap-x-4 gap-y-2">
			<b-button @click="saveNewSpecimen(false)" variant="primary">GUARDAR</b-button>
			<b-button @click="saveNewSpecimen(true)" variant="outline-primary">GUARDAR Y CREAR OTRO</b-button>
			<b-button @click="cleanFormSpecimen()" variant="outline-secondary">RESETEAR</b-button>
		</div>
		<b-modal
			id="modal-primary"
			ok-only
			ok-title="Agregar"
			modal-class="modal-primary"
			centered
			title="Agregar criador"
			v-model="addCriadorModal"
			@ok.prevent="saveRival"
		>
			<b-row>
				<b-col cols="12">
					<b-form-group label="Nombre" label-for="criadorName">
						<b-form-input id="criadorName" v-model="criadorObject.name" />
					</b-form-group>
				</b-col>
			</b-row>
		</b-modal>
		<b-modal
			centered
			:title="modalFastParent.padrillo ? 'AGREGAR PADRILLO' : 'AGREGAR MADRILLA'"
			v-model="modalFastParent.show"
			size="sm"
			@hidden="hiddenFastParent"
		>
			<b-overlay :show="modalFastParent.loading">
				<ValidationObserver ref="fastParentForm">
					<b-form-group label="Placa">
						<ValidationProvider rules="required" v-slot="{ errors }">
							<b-form-input :class="{ 'border-danger': errors[0] }" v-model="modalFastParent.plate" />
						</ValidationProvider>
					</b-form-group>
					<b-form-group label="Color">
						<ValidationProvider rules="required" v-slot="{ errors }">
							<b-form-select
								:class="{ 'border-danger': errors[0] }"
								v-model="modalFastParent.color"
								:options="modalFastParent.padrillo ? maleColors : femaleColors"
								value-field="id"
								text-field="color"
							/>
						</ValidationProvider>
					</b-form-group>
				</ValidationObserver>
			</b-overlay>
			<template #modal-footer>
				<b-button variant="success" @click="createFastParent">Guardar</b-button>
			</template>
		</b-modal>
		<AddBreederLineModal
			:show="breederLineModal.show"
			:info="breederLineModal.info"
			@closing="breederLineModal.show = false"
			@new-line="($event) => (createSpecimen.lines = $event)"
		/>
		<UpdateRaceModal
			:show="raceModal.show"
			:info="raceModal.info"
			@closing="raceModal.show = false"
			@new-line="($event) => (createSpecimen.races = $event)"
		/>
		<AddMeasures
			:show="measureModal.show"
			:info="measureModal.info"
			@closing="measureModal.show = false"
			@new-measures="($event) => (createSpecimen.measurements = $event)"
		/>
	</ValidationObserver>
</template>

<script>
import moment from "moment"
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"
import flatPickr from "vue-flatpickr-component"

import { Spanish } from "flatpickr/dist/l10n/es.js"

import SpecimenStatusChangedBody from "./components/SpecimenStatusChangedBody.vue"
import heic2any from "heic2any"
import specimensService from "@/services/specimens.service"
import SelectSpecimens from "./components/SelectSpecimens.vue"
import AddBreederLineModal from "./components/AddBreederLineModal.vue"
import UpdateRaceModal from "./components/UpdateRaceModal.vue"
import AddMeasures from "./dashboard/modals/AddMeasures.vue"

export default {
	name: "",
	components: {
		flatPickr,
		SpecimenStatusChangedBody,
		SelectSpecimens,
		AddBreederLineModal,
		UpdateRaceModal,
		AddMeasures,
	},
	props: {},
	created() {
		this.getAllColors()
	},
	async mounted() {
		this.isPreloading()
		this.createSpecimenSecondary = { ...this.createSpecimen }
		await Promise.all([
			this.getAllStatusSpecimens({ category_id: 1 }),
			this.A_GET_CATEGORIES_SPECIMENS({ specimen_id: null }),
			this.A_GET_COLORS_SPECIMENS(this.genderId),
			this.A_GET_ORIGINS_SPECIMENS(),
			this.getAliasDeads(),
		])
		this.isPreloading(false)
	},
	data() {
		return {
			config: {
				altInput: true,
				altFormat: "F j, Y",
				dateFormat: "Y-m-d",
				locale: Spanish,
			},
			createSpecimen: {
				created_by: null,
				origin_id: 1,
				padrillo_id: null,
				madrilla_id: null,
				padrillo_name: null,
				madrilla_name: null,
				breeder_id: null,
				price: null,
				category_id: 1,
				gender_id: 1,
				plate: null,
				plate_additional: null,
				alias: null,
				status_id: null,
				dob: null,
				weight: null,
				pounds_weight: 0,
				color_id: null,
				observation: null,
				photo: null,
				currency: null,
				description: null,
				reason: null,
				disease: null,
				others_description: null,
				client_id: null,
				new_status_id: 1,
				new_is_reason: null,
				date_status: moment().format("YYYY-MM-DD"),
				specimen_id: null,
				reason_death_id: null,
				evidences: [],
				customFormData: [],
				file: null,
				definition: null,
				lines: [],
				races: [],
				measurements: [],
			},
			createSpecimenSecondary: {},
			addColorModal: false,
			addCriadorModal: false,
			colorObject: {
				male: null,
				female: null,
			},
			arrayDeads: [
				{
					type: 3,
					id: null,
					observation: null,
					circumstances: null,
					evidences: [],
					customFormData: [],
					breeder_id: null, // criador
					others_description: null,
				},
			],
			duplicatePlate: false,
			criadorObject: {
				name: null,
			},
			aliasDeads: [],
			modalViewDefinitionAlias: {
				show: false,
				title: "Agregar definición de alias",
			},
			selectAliasDeadSpecimen: null,
			allColors: [],
			breederLineModal: { show: false, info: {} },
			raceModal: { show: false, info: {} },
			modalFastParent: {
				show: false,
				loading: false,
				padrillo: true,
				plate: null,
				color: null,
			},
			measureModal: { show: false, info: {} },
			// se usa para asignar los modelos de padres despues de crearlos con el boton +
			helperPadrillo: null,
			helperMadrilla: null,
			arrayStatustotal: [],
		}
	},
	computed: {
		...mapGetters("createEditSpecimen", ["G_ORIGINS_SPECIMENS_FILTER"]),
		...mapState("statusSpecimens", ["arrayUsers", "arrayStatus", "arrayReasons", "arrayDiseases", "arrayClients"]),
		...mapState("createEditSpecimen", [
			"S_EDIT_SPECIMEN",
			"S_CATEGORIES_SPECIMENS",
			"S_COLORS_SPECIMENS",
			"S_ORIGINS_SPECIMENS",
			"S_BREEDERS",
			"S_IMAGE_TO_UP",
			"S_PHOTOS",
			"S_GENDERS_SPECIMENS",
			"S_TRACKING_FIELDS",
			"S_PADRILLOS",
			"S_MADRILLAS",
			"S_OPTIONS_MACHO",
			"S_OPTIONS_HEMBRA",
		]),
		isBorrowed() {
			return this.createSpecimen.origin_id == 5 || this.createSpecimen.origin_id == 6
		},
		heightImage() {
			return ["xl"].includes(this.currentBreakPoint) ? "30rem" : "30rem"
		},
		paddingX() {
			return ["xl"].includes(this.currentBreakPoint) ? "px-5" : ""
		},
		genderId() {
			return this.createSpecimen.gender_id
		},
		maleColors() {
			return this.allColors.filter((c) => c.gender_id == 1)
		},
		femaleColors() {
			return this.allColors.filter((c) => c.gender_id == 2)
		},
		categoryOpts() {
			if (this.createSpecimen.origin_id !== 6) {
				return [...this.S_OPTIONS_MACHO, ...this.S_OPTIONS_HEMBRA]
			} else {
				return this.S_OPTIONS_MACHO
			}
		},
		getStatusLenght() {
			return this.arrayStatus.length
		},
	},
	methods: {
		...mapMutations("createEditSpecimen", ["M_SET_OPTIONS_MACHO", "M_SET_PADRILLOS", "M_SET_MADRILLAS"]),
		...mapMutations("statusSpecimens", ["M_SET_SPECIMENS_TO_CHANGE_STATUS"]),
		...mapActions("createEditSpecimen", [
			"A_GET_CATEGORIES_SPECIMENS",
			"A_GET_COLORS_SPECIMENS",
			"A_GET_ORIGINS_SPECIMENS",
			"A_GET_BREEDERS",
			"A_GET_TRACKING_FIELDS_SPECIMEN",
			"A_GET_SPECIMENS_BY_CATEGORY",
			"A_SAVE_SPECIMEN",
			"A_UPDATE_PRINCIPAL_PHOTO",
			"A_ADD_IMAGE_BY_ID",
		]),
		...mapActions("statusSpecimens", [
			"getUsersByRole",
			"getReasons",
			"getClients",
			"getDiseases",
			"getAllStatusSpecimens",
			"A_SAVE_STATUS_MASSIVELY",
		]),
		async getAllColors() {
			const { colores } = await specimensService.getColors({
				perpage: 1000,
				page: 1,
				search: null,
			})
			this.allColors = colores.data
		},
		async saveNewSpecimen(saveOther) {
			const validate = await this.$refs.form.validate()
			if (!validate) return

			this.isPreloading()

			//If is 'Camada'
			if (this.createSpecimen.origin_id === 1) {
				this.createSpecimen.breeder_id = 412
			}
			//USER
			this.createSpecimen.created_by = this.currentUser.user_id

			//if is death
			if (this.createSpecimen.new_status_id === 3) {
				this.createSpecimen.reason_death_id = this.createSpecimen.reason_id
				this.createSpecimen.evidences = this.arrayDeads[0].evidences
				this.createSpecimen.customFormData = this.arrayDeads[0].customFormData
			}
			//IF IS BORROWED
			if (this.isBorrowed) {
				this.setBorrowedPlate()
			}
			this.justNumbersPrice()
			try {
				const response = await this.A_SAVE_SPECIMEN(this.createSpecimen)
				if (response[0].new_specimen_id === "duplicate") {
					this.duplicatePlate = true
					this.showToast("warning", "top-right", "PLACA DUPLICADA", "AlertTriangleIcon", "Ingrese otra placa")
					return
				}
				this.createSpecimen.specimen_id = response[0].new_specimen_id
				this.M_SET_SPECIMENS_TO_CHANGE_STATUS([this.createSpecimen])
				if (this.createSpecimen.photo) {
					await this.uploadImage()
				}
				await this.A_SAVE_STATUS_MASSIVELY()
				this.showSuccessToast("Ejemplar guardado correctamente")
				if (saveOther) {
					this.cleanFormSpecimen()
					return
				}
				this.$router.push(`/ejemplares/detalle/${this.createSpecimen.specimen_id}`)
			} catch (error) {
				const { message } = error.response.data
				console.error(error)
			} finally {
				this.A_GET_COLORS_SPECIMENS()
				this.isPreloading(false)
			}
		},
		async uploadImage() {
			const image = this.createSpecimen.file
			const formData = new FormData()
			formData.append("file", image, image.data)
			formData.append("id_specimens", this.createSpecimen.specimen_id)
			formData.append("user_id", this.currentUser.user_id)
			formData.append("statusTope", false)
			formData.append("feature", 1)
			await this.A_ADD_IMAGE_BY_ID(formData)
		},
		setBorrowedPlate() {
			this.createSpecimen.plate_additional =
				this.createSpecimen.plate_additional.indexOf("P-") > -1
					? this.createSpecimen.plate_additional
					: `${this.createSpecimen.origin_id == 5 ? "P" : "R"}-${this.createSpecimen.plate_additional}`

			if (this.createSpecimen.plate) {
				this.createSpecimen.plate =
					this.createSpecimen.plate.indexOf("P-") > -1
						? this.createSpecimen.plate
						: `${this.createSpecimen.origin_id == 5 ? "P" : "R"}-${this.createSpecimen.plate}`
			}
			// this.createSpecimen.plate = this.createSpecimen.plate_additional;
		},
		convertKilosToPounds() {
			this.createSpecimen.pounds_weight = (this.createSpecimen.weight * 2.20462).toFixed(3)
		},
		setCurrencySpecimen(currency) {
			this.createSpecimen.currency = currency
		},
		justNumbersPrice() {
			if (this.createSpecimen.price?.length > 0) {
				this.createSpecimen.price = this.justNumbers(this.createSpecimen.price)
			}
		},
		fixedPrice() {
			if (this.createSpecimen.price && Number(this.createSpecimen.price)) {
				this.createSpecimen.price = this.fixedMoney(this.createSpecimen.price)
			}
		},
		pickFile() {
			let input = this.$refs.fileInput
			let file = input.files
			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
			if (typeOfFile === "heic" || typeOfFile === "heif") {
				this.isPreloading()
				this.showToast(
					"warning",
					"top-right",
					"Cargando imagen, espere un momento",
					"CheckIcon",
					"El formato HEIF o HEIC no es soportado, por ello lo puede tardar un momento."
				)
				heic2any({ blob: file[0], toType: "image/jpg", quality: 1 }).then((newImage) => {
					const url = URL.createObjectURL(newImage)
					let newFile = new File([newImage], "heic" + ".jpg", {
						type: "image/jpeg",
						lastModified: new Date().getTime(),
					})
					this.createSpecimen.file = newFile
					this.createSpecimen.photo = url
					this.isPreloading(false)
				})
			} else {
				this.createSpecimen.file = file[0]
				if (file && file[0]) {
					let reader = new FileReader()
					reader.onload = (e) => {
						this.createSpecimen.photo = e.target.result
					}
					reader.readAsDataURL(file[0])
					this.createSpecimen.file = file[0]
				}
			}
		},
		deletePhoto() {
			this.createSpecimen.photo = null
			this.createSpecimen.file = null
		},
		cleanFormSpecimen() {
			this.createSpecimen = { ...this.createSpecimenSecondary }
			this.$refs.form.reset()
		},
		async saveRival() {
			try {
				await axios.post("/api/save-rival", {
					namerival: this.criadorObject.name,
				})
				this.addCriadorModal = false
				// this.A_GET_BREEDERS();
				this.criadorObject.name = null
			} catch (error) {
				this.showErrorSwal(error)
			}
		},
		async getAliasDeads() {
			const { data } = await specimensService.getAliasSpecimensDeads()
			this.aliasDeads = data
		},
		clickDefinitionAlias() {
			this.modalViewDefinitionAlias.show = true
		},
		setSelectAliasDeadSpecimen() {
			this.createSpecimen.alias = this.selectAliasDeadSpecimen
		},
		hiddenFastParent() {
			this.modalFastParent = {
				show: false,
				loading: false,
				padrillo: true,
				plate: null,
				color: null,
			}
		},
		async createFastParent() {
			if (!(await this.$refs.fastParentForm.validate())) return
			this.modalFastParent.loading = true
			try {
				const { data } = await specimensService.insertParentSimple({
					category_id: this.modalFastParent.padrillo ? 1 : 2,
					color_id: this.modalFastParent.color,
					plate: this.modalFastParent.plate,
				})
				if (this.modalFastParent.padrillo) {
					this.helperPadrillo = {
						id: data.specimen_id,
						plate: this.modalFastParent.plate,
					}
				} else {
					this.helperMadrilla = {
						id: data.specimen_id,
						plate: this.modalFastParent.plate,
					}
				}
			} catch (error) {
				this.modalFastParent.loading = false
				return
			}
			this.modalFastParent.loading = false
			this.modalFastParent.show = false
			this.showToast(
				"success",
				"top-right",
				"Ejemplares",
				"AlertTriangleIcon",
				`${this.modalFastParent.padrillo ? "Nuevo padrillo" : "Nueva madrilla"} creado exitosamente`
			)
		},
	},
	watch: {
		async "createSpecimen.category_id"(val, old) {
			this.createSpecimen.new_status_id = null
			//MACHO
			await this.getAllStatusSpecimens({ category_id: val })
			if ([1, 3, 5].includes(val)) {
				this.createSpecimen.gender_id = 1
				if ([2, 4, 6].includes(old)) {
					this.createSpecimen.color_id = null
				}
			}
			//HEMBRA
			if ([2, 4, 6].includes(val)) {
				this.createSpecimen.gender_id = 2
				if ([1, 3, 4].includes(old)) {
					this.createSpecimen.color_id = null
				}
			}
		},
		"createSpecimen.origin_id"(val, old) {
			this.createSpecimen.padrillo_id = null
			this.createSpecimen.padrillo_text = null
			this.createSpecimen.madrilla_id = null
			this.createSpecimen.madrilla_text = null
			this.helperPadrillo = null
			this.helperMadrilla = null

			//GET BREEDERS
			if ([2, 3, 5, 6].includes(val) && !this.S_BREEDERS.length) {
				this.A_GET_BREEDERS()
			}
			//WHEN IS BORROWED
			if (val === 5) this.createSpecimen.plate = null
		},
		async "createSpecimen.new_status_id"(val) {
			//WHEN IS DEATH
			this.createSpecimen.reason_id = null
			if (val === 3) this.getReasons({ module_id: 1, status_id: val })
			const currentstatus = this.arrayStatustotal.find((st) => st.id == val)

			if (currentstatus) {
				this.createSpecimen.new_is_reason = currentstatus.is_reason
				if (currentstatus.is_reason == 1) {
					this.isPreloading()
					try {
						await this.getReasons({ module_id: 1, status_id: val })
					} catch (error) {
						console.log("error-reasons: ", error)
					} finally {
						this.isPreloading(false)
					}
				}
			}

			//WHEN IS ENFERMO, MUDA, PREPARACION, PRE-ENCASTE, HERIDO
			if ([6, 9, 10, 12, 13].includes(val)) this.getUsersByRole()

			//WHEN IS ENFERMO
			if (val === 6) this.getDiseases()

			//WHEN IS ENFERMO
			if (val === 6) this.getDiseases()

			//WHEN IS VENDIDO, REGALADO, PRESTADO,DONADO
			if ([2, 4, 5, 11].includes(val)) this.getClients()
		},
		"createSpecimen.plate"() {
			if (this.duplicatePlate) this.duplicatePlate = false
		},
		"createSpecimen.weight"(val) {
			this.convertKilosToPounds()
			if (!val) this.createSpecimen.pounds_weight = 0
		},
		genderId() {
			this.A_GET_COLORS_SPECIMENS(this.genderId)
		},
		getStatusLenght() {
			this.arrayStatustotal = this.arrayStatus
			this.arrayStatustotal = this.arrayStatustotal.map((it) => ({
				...it,
				selectable: true,
				description: `------ ${it.description}`,
			}))

			this.arrayStatustotal.unshift({
				id: null,
				description: "DISPONIBLES",
				selectable: false,
			})

			const indexInactivos = this.arrayStatustotal.findIndex((it) => it.in_galpon == 0)

			this.arrayStatustotal.splice(indexInactivos, 0, {
				id: null,
				description: "NO DISPONIBLES",
				selectable: false,
			})
		},
	},
}
</script>

<style lang="scss">
.label-size {
	font-size: 1.1rem;
}
.container-alias-deads {
	margin-top: -4px;
	width: 100%;
	p {
		margin-bottom: 0.5rem;
		color: rgb(103, 104, 104);
		span {
			font-size: 12px;
			color: rgba(103, 104, 104, 0.8);
		}
	}

	.grid-span {
		display: grid;
		gap: 0.5rem;
		grid-template-columns: 1fr;
	}
}
.animation-badge {
	transition: 0.3s all ease-in-out;
}
</style>
